import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAmplitudeExperiment } from '../utils/helpers'

const ExperimentContext = createContext();

const ExperimentProvider = ({ children }) => {
  const [ampExpTestPriceVariant, setAmpExpTestPriceVariant] = useState('testosterone_pricing_variation_0')

  useEffect(() => {
    getAmplitudeExperiment('testosterone_pricing', (variant) => {
      setAmpExpTestPriceVariant(variant.value)
      // temp hot fix for external links
      window._ampExpTestPriceVariant = variant.value
    })
  }, [])

  return (
    <ExperimentContext.Provider value={{ ampExpTestPriceVariant }}>
      {children}
    </ExperimentContext.Provider>
  );
};

const useExperimentData = () => {
  return useContext(ExperimentContext)
}

export { ExperimentProvider, useExperimentData }